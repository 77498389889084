import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accrodion";

const AccordionWrap = () => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            <AccordionItem id="one">
                <AccordionTitle id="one">
                    <span className="question-tag">Q:</span>What is the
                    difference between the Free and Premium plans?
                </AccordionTitle>
                <AccordionContent id="one">
                    Pleasure rationally encounter consequences that are
                    extremely painful. Nor again there who loves or pursues or
                    desires to obtain voluptas sit aspernatur aut odit aut
                    fugit, sed consequuntur magni dolores eos qui ratione
                    voluptatem sequi nesciunt. Neque porro est, qui dolorem
                    ipsum quia dolor sit amet, consectetur, adipisci velit
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitle id="two">
                    <span className="question-tag">Q:</span>How do I upgrade my
                    subscription?
                </AccordionTitle>
                <AccordionContent id="two">
                    Pleasure rationally encounter consequences that are
                    extremely painful. Nor again there who loves or pursues or
                    desires to obtain voluptas sit aspernatur aut odit aut
                    fugit, sed consequuntur magni dolores eos qui ratione
                    voluptatem sequi nesciunt. Neque porro est, qui dolorem
                    ipsum quia dolor sit amet, consectetur, adipisci velit
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitle id="three">
                    <span className="question-tag">Q:</span>Is there a
                    money-back guarantee?
                </AccordionTitle>
                <AccordionContent id="three">
                    Pleasure rationally encounter consequences that are
                    extremely painful. Nor again there who loves or pursues or
                    desires to obtain voluptas sit aspernatur aut odit aut
                    fugit, sed consequuntur magni dolores eos qui ratione
                    voluptatem sequi nesciunt. Neque porro est, qui dolorem
                    ipsum quia dolor sit amet, consectetur, adipisci velit
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWrap;
