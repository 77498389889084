import React from "react";
import PropTypes from "prop-types";
import BlogGrid from "../../../components/blog";
import Button from "../../../components/button";
import AccordionContainer from "../../accordion";
import SectionTitle from "../../../components/section-title";
const BlogItemContainer = ({ data }) => {
    return (
        <div className="container my-9 py-9">
            <div className="row">
                <div className="col-12 my-5 py-5">
                    <div id="grid" className="grid row mb-n7">
                        <div className="grid-item mb-7">
                            <div className="feature-card bg-light">
                                <span className="card-shape card-shape-light"></span>
                                <span className="card-shape card-shape-dark"></span>
                                <h4 className="title mb-6">Free Tier</h4>
                                <h5>Features:</h5>
                                <p className="ps-4">
                                    Unlimited Basic Scenarios <br /> AI-Assisted
                                    Dialogues <br /> Skill-Level Matching <br />
                                </p>
                                <h5 className="mb-5">Price:</h5>
                                <p className="ps-4">Free Forever</p>
                                <div className="text-center">
                                    <Button
                                        classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                        text="Get Started"
                                        path="/"
                                    />
                                    {/* <Button
                                        classOption="btn btn-lg mt-5 animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                        text="Join for Free"
                                        path="/"
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="grid-item card-mt-75 mb-7">
                            <div className="feature-card bg-light">
                                <span className="card-shape card-shape-light"></span>
                                <span className="card-shape card-shape-dark"></span>
                                <h4 className="title mb-6">Premium Tier</h4>
                                <h5>Features:</h5>
                                <p className="ps-4">
                                    All Free Features Plus: <br /> Access to
                                    Advanced Scenarios
                                    <br /> Priority Customer Support <br />{" "}
                                    Performance Analytics
                                </p>
                                <h5>Price:</h5>
                                <p className="ps-4">$9.99/month</p>
                                <div className="text-center">
                                    <Button
                                        classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                        text="Upgrade Now"
                                        path="/"
                                    />
                                    <Button
                                        classOption="btn btn-lg mt-5 animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                        text="Go Premium"
                                        path="/"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grid-item mb-7">
                            <div className="feature-card bg-light">
                                <span className="card-shape card-shape-light"></span>
                                <span className="card-shape card-shape-dark"></span>
                                <h4 className="title mb-6">Pro Tier</h4>
                                <h5>Features:</h5>
                                <p className="ps-4">
                                    All Premium Features Plus: <br /> One-on-One
                                    Coaching Sessions
                                    <br /> Customizable Scenarios <br />
                                    Multi-Language Support
                                </p>
                                <h5>Price:</h5>
                                <p className="ps-4">$19.99/month</p>
                                <div className="text-center">
                                    <Button
                                        classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                        text="Go Pro"
                                        path="/"
                                    />
                                    <Button
                                        classOption="btn btn-lg animated mt-5 delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                        text="Unlock All Features"
                                        path="/"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <AccordionContainer />
            </div>
            <div className="row ">
                <div className="col-lg-8 col-xl-10 container mt-5 pt-5">
                    <div className="about-content mt-5 pt-5 team-card">
                        <SectionTitle
                            classOption="title-section"
                            subTitle=""
                            title="Ready to take your language skills to the next level? Choose your plan today"
                        />
                        <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark me-4"
                            text="Start Your Journey"
                            path="/about"
                        />
                        <Button
                            classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0 ml-4 mr-4"
                            text="Choose Your Plan"
                            path="/contact"
                        />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

BlogItemContainer.propTypes = {
    data: PropTypes.array,
};

export default BlogItemContainer;
