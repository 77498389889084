import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TeampPageContainer from "../containers/team-page-container";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import Button from "../components/button";
import SectionTitle from "../components/section-title";

const TeamPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="SpeakNest – How It Works" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="How It Works"
                        excerpt="Simple Steps to Transform Your Language Learning Journey <br />
                        Uncomplicate language learning with our easy-to-follow process."
                        image="./images/team/5.png"
                    />
                    <div className="container">
                        <div className="step-container">
                            <div className="step">
                                <div className="step-number">Step 1</div>
                                <div className="step-content">
                                    <h2>Sign Up and Assess</h2>
                                    <p>
                                        Join the SpeakNest community by signing
                                        up and completing a quick language
                                        assessment.
                                    </p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="step-number">Step 2</div>
                                <div className="step-content">
                                    <h2>Choose a Scenario</h2>
                                    <p>
                                        Browse through our curated list of
                                        real-world scenarios to find your
                                        perfect fit.
                                    </p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="step-number">Step 3</div>
                                <div className="step-content">
                                    <h2>Role-play and Practice</h2>
                                    <p>
                                        Engage in a role-play session, either
                                        solo or with a partner, to bring your
                                        chosen scenario to life.
                                    </p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="step-number">Step 4</div>
                                <div className="step-content">
                                    <h2>AI-assisted Feedback</h2>
                                    <p>
                                        Receive real-time feedback and
                                        suggestions from our AI, ensuring a
                                        smooth and informative conversation.
                                    </p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="step-number">Step 5</div>
                                <div className="step-content">
                                    <h2>Review and Level Up</h2>
                                    <p>
                                        After your session, review your
                                        performance, get comprehensive feedback,
                                        and prepare for the next level.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container my-9 py-9">
                        <div className="row ">
                            <div className="col-lg-8 col-xl-10 container mt-5 pt-5">
                                <div className="about-content mt-5 pt-5 team-card">
                                    <SectionTitle
                                        classOption="title-section"
                                        subTitle=""
                                        title="So what are you waiting for? Start speaking, start learning, start now."
                                    />
                                    <Button
                                        classOption="btn btn-lg btn-dark btn-hover-dark me-4"
                                        text="Get Started"
                                        path="/"
                                    />
                                    <Button
                                        classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0 ml-4 mr-4"
                                        text="Join Now"
                                        path="/"
                                    />
                                    <br />
                                    <Button
                                        classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mt-5 mb-3 mb-sm-0 ml-4 mr-4"
                                        text="Start Speaking"
                                        path="/"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default TeamPage;
