import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import Button from "../../../components/button";

const ServiceListContainer = () => {
    return (
        <div className="service-section position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-8 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle=""
                            title="Join us on this exciting journey to make language learning more interactive than ever."
                            excerptClassOption="null"
                            excerpt=""
                        />
                    </div>
                    <div className="col-xl-5 col-lg-4 mx-auto">
                        <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark me-4 w-50"
                            text="Learn More"
                            path="/"
                        />
                        <Button
                            classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0 ml-4 mr-4"
                            text="Join Now"
                            path="/"
                        />
                        <br />
                        <a
                            className="btn btn-lg btn-dark btn-hover-dark mt-5 w-50"
                            href="http://38.113.162.155:3000/SpeakNest.apk"
                        >
                            Download App
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
