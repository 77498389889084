import PropTypes from "prop-types";
import Button from "../../components/button";

const PageTitle = ({ title, excerpt }) => {
    return (
        <div className="banner-content banner-padding">
            <h3 className="title">{title}</h3>
            <p dangerouslySetInnerHTML={{ __html: excerpt }}></p>

            {/* <Button
                classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0 px-10"
                text="Download"
                path="/about"
            /> */}
        </div>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
};

export default PageTitle;
