import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

// Original Button component, kept as the default export
const Button = ({ classOption, text, path }) => {
    return (
        <React.Fragment>
            <Link
                to={process.env.PUBLIC_URL + path}
                className={`${classOption}`}
            >
                {text}
            </Link>
        </React.Fragment>
    );
};

Button.propTypes = {
    classOption: PropTypes.string,
    text: PropTypes.string,
    path: PropTypes.string,
};
Button.defaultProps = {
    classOption: "btn",
};

// New Component: ButtonNew
const ButtonNew = ({ classOption, text, path }) => {
    const isExternal = path.startsWith("http");

    return (
        <React.Fragment>
            {isExternal ? (
                <a
                    href={path}
                    className={`${classOption}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {text}
                </a>
            ) : (
                <Link
                    to={process.env.PUBLIC_URL + path}
                    className={`${classOption}`}
                >
                    {text}
                </Link>
            )}
        </React.Fragment>
    );
};

ButtonNew.propTypes = {
    classOption: PropTypes.string,
    text: PropTypes.string,
    path: PropTypes.string,
};
ButtonNew.defaultProps = {
    classOption: "btn",
};

export default Button;
export { ButtonNew };
