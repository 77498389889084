import React from "react";
import Newsletter from "../../../components/newsletter";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";

const NewsletterArea = () => {
    return (
        <div className="news-letter-section section-pb mt-5 pt-5">
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            excerpt="Never Run Out of Words Again"
                            title="AI-assisted Dialogues"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-10 container">
                        <h3 className="fw-bold pt-5 mt-5">
                            Why AI-assistance?
                        </h3>
                        <p className="ps-5 pt-4">
                            Our AI suggests phrases and keywords, helping you
                            express yourself effortlessly.
                        </p>
                        <p className="ps-5">
                            Make awkward silences and conversation lulls a thing
                            of the past.
                        </p>
                        <h3 className="fw-bold pt-5 mt-5">What to Expect?</h3>
                        <p className="ps-5 pt-4">
                            Real-time suggestions based on the flow of your
                            conversation.
                        </p>
                        <p className="ps-5">
                            Contextual advice to help you navigate through
                            different topics and scenarios.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            excerpt="A Community Where Everyone Grows Together"
                            title="Skill-level Matching"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-10 container">
                        <h3 className="fw-bold pt-5 mt-5">
                            Why Skill-level Matching?
                        </h3>
                        <p className="ps-5 pt-4">
                            Practicing with peers at your level makes learning
                            more effective.
                        </p>
                        <p className="ps-5">
                            Say goodbye to intimidation and hello to balanced,
                            beneficial practice.
                        </p>
                        <h3 className="fw-bold pt-5 mt-5">What to Expect?</h3>
                        <p className="ps-5 pt-4">
                            Automated, yet personalized matching based on your
                            language proficiency.
                        </p>
                        <p className="ps-5">
                            An ever-growing community where everyone is a
                            learner and a teacher.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-10 container mt-5 pt-5">
                        <div className="about-content mt-5 pt-5 team-card">
                            <SectionTitle
                                classOption="title-section"
                                subTitle=""
                                title="Ready to experience the future of language learning? Join SpeakNest now."
                            />
                            <p className="mb-5">Download SpeakNest now.</p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark me-4"
                                text="Get Started"
                                path="/"
                            />
                            <Button
                                classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0 ml-4 mr-4"
                                text="Start Your Journey"
                                path="/"
                            />
                            <br />
                            <a
                                className="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                href="http://38.113.162.155:3000/SpeakNest.apk"
                            >
                                Download New
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterArea;
