import React from "react";

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";
import Button from "../../components/button";

const ContactContainer = () => {
    return (
        <section className="contact-section mt-10 pt-10">
            <div className="container mt-10">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">Business Hours</h3>
                            <p>
                                Our customer support is available from 9 am to 6
                                pm, Monday to Friday.
                            </p>
                            <h3 className="title">Office Location</h3>
                            <p>Our headquarters is located at Toronto.</p>
                            <h3 className="title pt-5 mt-5">
                                Let’s make your language learning journey
                                extraordinary. Get in touch today.
                            </h3>
                            <Button
                                classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                text="Send Message"
                                path="/"
                            />
                            <Button
                                classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                text="Contact Us"
                                path="/"
                            />
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 mb-7 offset-xl-1">
                        <div className="contact-address text-center">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    return (
                                        <ContactInfo key={key} data={single} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
